/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { ForwardRef } from '../types'

export const Input: ForwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = React.forwardRef((props, ref) => (
  <input
    ref={ref}
    sx={{
      fontSize: 3,
      width: '100%',
      outline: 'none',
      border: 'none',
      ':disabled': {
        backgroundColor: 'gray.0',
        opacity: 0.5,
        color: 'lightText',
        pointerEvents: 'none',
      },
    }}
    {...props}
  />
))
