/** @jsx jsx */
import { jsx, SxStyleProp } from 'theme-ui'
import * as React from 'react'
import { ForwardRef } from '../types'

export const Card: ForwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    isFocused?: boolean
    hasErrors?: boolean
  }
> = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    sx={{
      borderRadius: 'sm',
      borderColor: 'border',
      borderWidth: 1,
      borderStyle: 'solid',
      bg: 'white',
    }}
    {...props}
  />
))

export const cardContentProps: SxStyleProp = {
  py: [2, 2, 3],
  px: [2, 3, 4],
}

export const lastCardContent: SxStyleProp = {
  py: [2, 2, 3],
  px: [2, 3, 4],
  borderBottomLeftRadius: 'sm',
  borderBottomRightRadius: 'sm',
}

export const firstCardContent: SxStyleProp = {
  py: [2, 2, 3],
  px: [2, 3, 4],
  borderTopLeftRadius: 'sm',
  borderTopRightRadius: 'sm',
}
