/** @jsx jsx */
import { jsx, Heading as BaseHeading, HeadingProps } from 'theme-ui'
import React from 'react'
import { ForwardRef, WithVariants } from '../types'

export const Heading: ForwardRef<
  HTMLHeadingElement,
  WithVariants<
    HeadingProps & { styledAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' },
    'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  >
> = React.forwardRef(({ styledAs, ...props }, ref) => (
  <BaseHeading
    as="h1"
    sx={{
      fontWeight: 'light',
      fontSize: (() => {
        switch (styledAs || props.as) {
          case 'h1':
          case undefined:
            return [5, 5, 6, null, null, 8]
          case 'h2':
            return [4, 4, 5, null, null, 7]
          case 'h3':
            return [3, 3, 4, null, null, 6]
          case 'h4':
            return [2, 2, 3, null, null, 4]
        }
      })(),
    }}
    ref={ref}
    {...props}
  />
))
