/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { ForwardRef } from '../types'
import { theme } from '../gatsby-plugin-theme-ui/theme'
import { Divider } from './Divider'
import { Card, cardContentProps } from './Card'
import { Input } from './Input'
import { Txt } from '.'
import { Select } from './Select'

export const FormGroupLabel: ForwardRef<
  HTMLLabelElement,
  React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
> = React.forwardRef((props, ref) => (
  <label ref={ref} sx={{ ...cardContentProps, width: '100%', display: 'block' }} {...props} />
))

export const FormGroupContent: ForwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = React.forwardRef((props, ref) => <div ref={ref} sx={cardContentProps} {...props} />)

export const FormGroup: ForwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    isFocused?: boolean
    hasErrors?: boolean
  }
> = React.forwardRef(({ isFocused, hasErrors, ...props }, ref) => (
  <Card
    ref={ref}
    sx={{
      borderColor: hasErrors
        ? theme.colors.red
        : isFocused
        ? theme.colors.darkBlue
        : theme.colors.border,
    }}
    {...props}
  />
))

export const FormGroupDivider: React.SFC<{
  isFocused?: boolean
  hasErrors?: boolean
}> = ({ isFocused, hasErrors }) => (
  <Divider sx={{ borderColor: hasErrors ? 'error' : isFocused ? 'darkBlue' : 'border' }} />
)

export const FormGroupInput: ForwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    hasErrors?: boolean
  }
> = React.forwardRef(({ hasErrors, ...props }, ref) => (
  <Input ref={ref} sx={cardContentProps} {...props} />
))

export const FormGroupSelect: ForwardRef<
  HTMLSelectElement,
  React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    hasErrors?: boolean
  }
> = React.forwardRef(({ hasErrors, ...props }, ref) => (
  <Select ref={ref} sx={cardContentProps} {...props} />
))

export const FormGroupHelperText: React.SFC<{}> = (props) => (
  <Txt sx={{ fontSize: [1, 1, 2], color: 'lightText' }} {...props} />
)
