/** @jsx jsx */
import { jsx, SxStyleProp, useThemeUI } from 'theme-ui'
import React from 'react'
import { Link as BaseLink, Box as BaseBox } from 'theme-ui'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import _ from 'lodash'
import { SpaceProps } from 'styled-system'
import { ForwardRef, WithVariants } from '../types'

export const InternalLink: ForwardRef<HTMLAnchorElement, GatsbyLinkProps<any>> = React.forwardRef(
  (props, ref) => (
    <BaseBox
      // @ts-ignore
      ref={ref}
      as={GatsbyLink}
      variant="styles.a"
      {...props}
      __themeKey="links"
    />
  ),
)

export const List: ForwardRef<
  HTMLUListElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>
> = React.forwardRef((props, ref) => <ul ref={ref} {...props} />)

export const ListItem: ForwardRef<
  HTMLLIElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement>
> = React.forwardRef((props, ref) => <li ref={ref} {...props} />)

const important = (val?: string | number) =>
  val && (_.isString(val) ? val : (theme: any) => `${theme.space![val]}px !important`)

export const SpaceChildren: React.SFC<
  {
    childrenStyles: { mb?: string | number; mt?: string | number }
  } & React.ComponentPropsWithoutRef<typeof BaseBox>
> = ({ childrenStyles: { mb, mt }, ...props }) => {
  return (
    <BaseBox
      sx={{
        '& > *': {
          mb: important(mb),
          mt: important(mt),
        },
      }}
      {...props}
    />
  )
}

export {
  Box,
  Flex,
  Link as ExternalLink,
  Text as Txt,
  Image as Img,
  Label,
  Select,
  Textarea,
  Radio,
  Checkbox,
  Slider,
  Progress,
  Donut,
  Spinner,
  Avatar,
  Badge,
  Close,
  Alert,
  Embed,
  AspectRatio,
  AspectImage,
  NavLink,
  Message,
  IconButton,
  MenuButton,
} from 'theme-ui'
export { SEO } from './SEO'
export { Input } from './Input'
export { FormattedCurrency } from './FormattedCurrency'
export { Button, ButtonInternalLink } from './Button'
export { Divider } from './Divider'
export {
  FormGroupLabel,
  FormGroupContent,
  FormGroup,
  FormGroupDivider,
  FormGroupInput,
} from './FormGroup'
export { TextOnTable } from './TextOnTable'
export { Loading } from './Loading'
export { LoadingPlaceholder } from './LoadingPlaceholder'
export { Card } from './Card'
export { Container } from './Container'
export { Heading } from './Heading'
export { Row } from './Row'
export { Col } from './Col'
export { CheckboxAndLabel } from './CheckboxAndLabel'
export { Collapse } from './Collapse'
