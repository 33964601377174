import React from 'react'

const commasToNumber = (amount: number | string): string => {
  return `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export interface IFormattedNumberProps {
  amount: number
  format?: 'shortest-abbreviation'
  decimal?: boolean
}

export const formatNumberAmount = ({ amount, decimal, format }: IFormattedNumberProps) => {
  const absAmount = Math.abs(amount)
  const lead = amount < 0 ? `-` : ''
  switch (format) {
    case 'shortest-abbreviation':
      return `${lead}${
        absAmount >= 1000
          ? `${commasToNumber(
              absAmount % 1000 ? (absAmount / 1000).toFixed(1) : absAmount / 1000,
            )}K`
          : commasToNumber(absAmount)
      }`
    default:
      return `${lead}${commasToNumber(absAmount.toFixed(decimal ? 2 : 0))}`
  }
}

export const FormattedNumber: React.SFC<IFormattedNumberProps> = (props) => (
  <>{formatNumberAmount(props)}</>
)
