/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import classNames from 'classnames'
import { Box } from 'theme-ui'
import './Row.css'

const widths = ['xs', 'sm', 'md', 'lg', 'xl']

export const Row: React.SFC<{
  noGutters?: boolean
  form?: boolean
  xs?: number | string
  sm?: number | string
  md?: number | string
  lg?: number | string
  xl?: number | string
  className?: string
}> = (props) => {
  const { noGutters, form, className, ...attributes } = props

  const colClasses: string[] = []

  widths.forEach((colWidth, i) => {
    // @ts-ignore
    let colSize = props[colWidth]
    // @ts-ignore
    delete attributes[colWidth]

    if (!colSize) {
      return
    }

    const isXs = !i
    colClasses.push(isXs ? `row-cols-${colSize}` : `row-cols-${colWidth}-${colSize}`)
  })

  const classes = classNames(
    noGutters ? 'no-gutters' : null,
    form ? 'form-row' : 'row',
    colClasses,
    className,
  )
  return <div {...attributes} className={classes} />
}
