/** @jsx jsx */
import _ from 'lodash'
import React from 'react'
import { jsx } from 'theme-ui'
// @ts-ignore
import loadingDark from './LoadingDark.svg'
// @ts-ignore
import loadingLight from './LoadingLight.svg'

export interface ILoadingProps {
  loadingVariant: 'light' | 'dark'
}

export const Loading: React.SFC<ILoadingProps> = ({ loadingVariant }) => {
  return (
    <img sx={{ height: '1em' }} src={loadingVariant === 'light' ? loadingLight : loadingDark} />
  )
}
