import React from 'react'
import { formatNumberAmount, IFormattedNumberProps } from './FormattedNumber'

// eventually start using Intl API for this once we aren't US only

const currencyMap: { [key: string]: string } = {
  USD: '$',
}

interface IFormattedCurrencyProps extends IFormattedNumberProps {
  currency?: string
}

const formatCurrencyAmount = ({ amount, currency, ...props }: IFormattedCurrencyProps) =>
  `${amount < 0 ? '-' : ''}${currencyMap[currency || 'USD']}${formatNumberAmount({
    ...props,
    amount,
  }).replace('-', '')}`

export const FormattedCurrency: React.SFC<IFormattedCurrencyProps> = (props) => (
  <>{formatCurrencyAmount(props)}</>
)
