import * as React from 'react'
import { Loading, ILoadingProps } from './Loading'

export const LoadingPlaceholder: React.SFC<
  ILoadingProps & {
    loading: boolean
    loadingContent?: React.ReactNode
    opacity?: number
  }
> = ({ loading, children, loadingContent, loadingVariant, opacity = 0.2 }) => {
  return (
    <div style={{ position: 'relative' }}>
      {loading && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
          }}
        >
          {loadingContent || <Loading loadingVariant={loadingVariant} />}
        </div>
      )}
      <div style={{ opacity: loading ? opacity : 1 }}>{children}</div>
    </div>
  )
}
