/** @jsx jsx */
import { jsx, SxStyleProp, Button as BaseButton } from 'theme-ui'
import React from 'react'
import { ForwardRef } from '../types'
import { GatsbyLinkProps, Link } from 'gatsby'

export interface ICustomButtonProps {
  variant?: 'link' | 'lightGreen'
  block?: boolean
  size?: 'sm' | 'md' | 'lg'
}

export interface IButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    ICustomButtonProps {}

export const Button: ForwardRef<HTMLButtonElement, IButtonProps> = React.forwardRef(
  ({ variant, block, size, ...props }, ref) => (
    <BaseButton
      ref={ref}
      type="button"
      css={{
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      }}
      sx={{
        textRendering: 'optimizeLegibility',
        appearance: 'none',
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: 'inherit',
        textDecoration: 'none',
        fontSize: 'inherit',
        fontWeight: 'bold',
        m: 0,
        border: 'none',
        borderRadius: 'pill',
        bg: 'darkBlue',
        color: 'white',
        borderColor: 'darkBlue',
        borderWidth: '1px',
        borderStyle: 'solid',
        ':disabled': {
          opacity: 0.3,
        },
        ...(block ? { width: '100%', display: 'block' } : {}),
        ...(() => {
          switch (size) {
            case 'sm':
              return { px: 3, py: 3, fontSize: 1 }
            case 'lg':
              return {
                px: 5,
                py: 4,
              }
            case 'md':
            default:
              return { px: 3, py: 3 }
          }
        })(),
        ...(() => {
          switch (variant) {
            case 'link': {
              return {
                textTransform: 'initial',
                verticalAlign: 'initial',
                px: 0,
                py: 0,
                color: 'link',
                bg: 'transparent',
                borderRadius: 0,
                borderColor: 'transparent',
                textAlign: 'left',
                lineHeight: '1.5',
                '&:hover, &:focus': {
                  textDecoration: 'underline',
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  boxShadow: 'none',
                },
              }
            }
            case 'lightGreen': {
              return {
                bg: 'lightGreen',
                borderColor: 'lightGreen',
                color: 'white',
                textShadow: `1px 1px 6px rgba(0,0,0,.4)`,
              }
            }
            default:
              return {}
          }
        })(),
      }}
      {...props}
    />
  ),
)

export const ButtonInternalLink: ForwardRef<
  HTMLAnchorElement,
  GatsbyLinkProps<any> & ICustomButtonProps
> = React.forwardRef(({ ...props }, ref) => (
  <Button
    as={Link}
    // @ts-ignore
    ref={ref}
    {...props}
  />
))
