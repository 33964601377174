import React from 'react'
import { Box } from '.'
import theme from '../gatsby-plugin-theme-ui'

export const CheckboxAndLabel: React.SFC<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    name: string
    topOffset?: number
  }
> = ({ children, name, topOffset, ...props }) => {
  const offset = topOffset || 0
  return (
    <Box
      css={{
        position: `relative`,
        "input[type='checkbox']": {
          opacity: `0`,
          position: `absolute`,
          left: `0`,
          zIndex: -1,
          top: `${offset}px`,
        },
        label: {
          position: `relative`,
          display: `inline-block`,
          /*22px width of fake checkbox + 8px distance between fake checkbox and text*/
          paddingLeft: `32px`,
        },
        'label::before, label::after': {
          position: `absolute`,
          content: `''`,

          /*Needed for the line-height to take effect*/
          display: `inline-block`,
        },
        /*Outer box of the fake checkbox*/
        'label::before': {
          height: `22px`,
          width: `22px`,
          backgroundColor: `${theme.colors.white}`,
          border: `solid 2px ${theme.colors.gray[2]}`,
          borderRadius: `${theme.radii.sm}px`,
          left: `0px`,

          /*(24px line-height - 22px height of fake checkbox) / 2 - 1px for the border
           *to vertically center it.
           */
          top: `${offset}px`,
        },
        /*Checkmark of the fake checkbox*/
        'label::after': {
          height: `8px`,
          width: `14px`,
          borderLeft: `2px solid ${theme.colors.darkBlue}`,
          borderBottom: `2px solid ${theme.colors.darkBlue}`,

          transform: `rotate(-45deg)`,

          left: `4px`,
          top: `${offset + 5}px`,
        },

        /*Hide the checkmark by default*/
        "input[type='checkbox'] + label::after": {
          content: `none`,
        },

        /*Unhide on the checked state*/
        "input[type='checkbox']:checked + label::after": {
          content: `''`,
        },
        "input[type='checkbox']:checked + label::before": {
          borderColor: `${theme.colors.darkBlue}`,
        },

        /*Adding focus styles on the outer-box of the fake checkbox*/
        "input[type='checkbox']:focus + label::before": {
          outline: `rgb(59, 153, 252) auto 2px`,
        },
      }}
    >
      <input type="checkbox" name={name} id={name} {...props} />
      <label htmlFor={name}>{children}</label>
    </Box>
  )
}
