/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import { Box } from 'theme-ui'

const widths = ['xs', 'sm', 'md', 'lg', 'xl']

type IColumnProps =
  | boolean
  | number
  | string
  | { size?: boolean | number | string; order?: number | string; offset?: number | string }

const getColumnSizeClass = (isXs: boolean, colWidth: string, colSize: any) => {
  if (colSize === true || colSize === '') {
    return isXs ? 'col' : `col-${colWidth}`
  } else if (colSize === 'auto') {
    return isXs ? 'col-auto' : `col-${colWidth}-auto`
  }

  return isXs ? `col-${colSize}` : `col-${colWidth}-${colSize}`
}

export const Col: React.SFC<{
  xs?: IColumnProps
  sm?: IColumnProps
  md?: IColumnProps
  lg?: IColumnProps
  xl?: IColumnProps
  className?: string
}> = (props) => {
  const { ...attributes } = props
  const colClasses = []

  widths.forEach((colWidth, i) => {
    // @ts-ignore
    let columnProp: IColumnProps = props[colWidth]

    // @ts-ignore
    delete attributes[colWidth]

    if (!columnProp && columnProp !== '') {
      return
    }

    const isXs = !i

    if (_.isObject(columnProp)) {
      const colSizeInterfix = isXs ? '-' : `-${colWidth}-`
      const colClass = getColumnSizeClass(isXs, colWidth, columnProp.size)

      colClasses.push(
        classNames({
          [colClass]: columnProp.size || columnProp.size === '',
          [`order${colSizeInterfix}${columnProp.order}`]:
            columnProp.order || columnProp.order === 0,
          [`offset${colSizeInterfix}${columnProp.offset}`]:
            columnProp.offset || columnProp.offset === 0,
        }),
      )
    } else {
      const colClass = getColumnSizeClass(isXs, colWidth, columnProp)
      colClasses.push(colClass)
    }
  })

  if (!colClasses.length) {
    colClasses.push('col')
  }

  const classes = classNames(colClasses, props.className)

  return <div {...attributes} className={classes} />
}
