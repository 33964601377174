/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Location } from '@reach/router'
import _ from 'lodash'
import React from 'react'
import { Helmet } from 'react-helmet'
import { fullUrl } from '../utils/urls'

export interface SEOProps {
  description?: string
  title: string
  image?: {
    url: string
    height?: number
    width?: number
  }
  pathname?: string
  noIndex?: boolean
  type?: 'article'
  tags?: string[]
  section?: string
  datetimePublished?: string
}

const addTrailingSlash = (str: string) => (_.endsWith(str, '/') ? str : `${str}/`)

export const SEO: React.SFC<SEOProps> = ({
  title,
  description,
  image: passedImage,
  pathname,
  noIndex,
  type,
  tags,
  section,
  datetimePublished,
  children,
}) => {
  const image = passedImage!
  return (
    <Location>
      {({ location }) => {
        const url = addTrailingSlash(fullUrl(pathname || location.pathname))
        return (
          <Helmet title={title} titleTemplate={'%s | OurPokerGame'} htmlAttributes={{ lang: 'en' }}>
            <meta name="description" content={description} />
            <meta name="image" content={image.url} />
            <link rel="canonical" href={url} />
            {image.width && <meta property="og:image:width" content={`${image.width}`} />}
            {image.height && <meta property="og:image:height" content={`${image.height}`} />}
            <meta property="og:site_name" content="OurPokerGame" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:type" content={type || 'website'} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image.url} />
            <meta property="og:image:secure_url" content={image.url} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image.url} />
            {/* <meta name="twitter:site" content="@OurPokerGame" />
            <meta name="twitter:creator" content="@OurPokerGame" />
            {type === 'article' && (
              <meta property="article:publisher" content="https://facebook.com/OurPokerGame" />
            )} */}
            {datetimePublished && (
              <meta property="article:published_time" content={datetimePublished} />
            )}
            {section && <meta property="article:section" content={section} />}
            {tags && tags.map((tag) => <meta key={tag} property="article:tag" content={tag} />)}
            {noIndex && <meta name="robots" content="noindex" />}
            {children}
          </Helmet>
        )
      }}
    </Location>
  )
}

SEO.defaultProps = {
  description:
    'OurPokerGame lets you set up an online poker game with your friends in less than 60 seconds. Free to play forever.',
  image: {
    url: 'IMAGE_TODO',
    height: 600,
    width: 1200,
  },
}
