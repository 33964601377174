export const fullUrl = (path: string) => `https://www.ourpokergame.com${path}`

export const urls = {
  home: () => '/',
  gameDetails: (id: string) => `/game/?id=${id}`,
  newGameForm: () => `/new-game/`,
  demoGame: () => `/demo-game/`,
  privacyPolicy: () => `/privacy-policy/`,
  termsAndConditions: () => `/terms-and-conditions/`,
  mailToSupport: () => `mailto:support@ourpokergame.com`,
}
