/** @jsx jsx */
import React from 'react'
import { jsx, Box, Flex } from 'theme-ui'

export const TextOnTable: React.SFC<{}> = ({ ...props }) => (
  <Box
    sx={{ textAlign: 'center', color: 'white' }}
    css={{
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      textRendering: 'optimizeLegibility',
      textShadow: `0px 0px 6px rgba(255,255,255,.2)`,
    }}
    {...props}
  />
)
