/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { ForwardRef } from '../types'

export const Select: ForwardRef<
  HTMLSelectElement,
  React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>
> = React.forwardRef((props, ref) => (
  <select
    ref={ref}
    sx={{
      bg: 'white',
      fontSize: 3,
      width: '100%',
      outline: 'none',
      border: 'none',
      appearance: 'none',
      backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAR0lEQVR4Ae3OMREAIBDEQCQgBf8qkIID+P4qqnyRnUmfIUmS1NesdnU/O9VqMJUz5FTMAFPcTE7xMzHFz8QUPxNT/IwkSUoPu7I4F2kEFg8AAAAASUVORK5CYII=')`,
      // background-position: calc(100% - 12px) 50%;
      backgroundPosition: `95% 50%`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `28px 32px`,
      '::-ms-expand': {
        display: 'none',
      },
      ':disabled': {
        backgroundColor: 'gray.0',
        opacity: 0.5,
        color: 'lightText',
        pointerEvents: 'none',
      },
    }}
    {...props}
  />
))
