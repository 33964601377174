/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { ForwardRef } from '../types'

export const Container: ForwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    {...props}
    sx={{
      maxWidth: 1140,
      px: [3, 4],
      mx: 'auto',
    }}
  />
))
