/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { ForwardRef } from '../types'

export const Divider: ForwardRef<
  HTMLHRElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>
> = React.forwardRef((props, ref) => (
  <hr ref={ref} sx={{ borderStyle: 'solid', borderColor: 'border', borderWidth: 1 }} {...props} />
))
